import * as client_hooks from '../../../src/hooks.client.js';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103')
];

export const server_loads = [0,10];

export const dictionary = {
		"/": [12],
		"/about/beta": [83],
		"/about/founder-story": [84],
		"/about/manifesto": [85],
		"/about/[slug]": [82],
		"/ageist": [86],
		"/app-home": [87],
		"/app-home/vibes": [88],
		"/(app)/assets/g/lists/og1/[id]": [40,[6,7]],
		"/(app)/assets/g/trust-score/og1/[id]": [41,[6,8]],
		"/(app)/assets/g/u/[slug]/stack/og1": [42,[6,9]],
		"/ben": [89],
		"/(app)/brands": [43,[2]],
		"/(app)/brands/[slug]": [44,[2]],
		"/(app)/brands/[slug]/trustscore": [45,[2]],
		"/(app)/collective": [~46,[2,10]],
		"/(app)/collective/posts/[slugWithId]": [~47,[2,10]],
		"/contact": [90],
		"/delete-account": [91],
		"/digest": [92],
		"/(app)/discover": [48,[2]],
		"/(app)/download": [49,[2]],
		"/(app)/(private)/feedback": [13,[2,3]],
		"/get-started": [93],
		"/get-started/find-trusted-products": [94],
		"/get-started/get-recommendations": [95],
		"/get-started/get-reminders": [96],
		"/get-started/optimize-my-stack": [97],
		"/get-started/save-money": [98],
		"/(app)/(private)/home": [14,[2,3]],
		"/(app)/(private)/internal/app-review-status": [15,[2,3]],
		"/(app)/(private)/internal/chat": [16,[2,3]],
		"/(app)/lists/[id]": [50,[2]],
		"/lp/[slug]": [99],
		"/(app)/(private)/mocks/auth/signin": [17,[4]],
		"/(app)/(private)/my/cabinet": [18,[2,3]],
		"/(app)/(private)/my/lists": [19,[2,3]],
		"/(app)/(private)/my/lists/[id]": [20,[2,3]],
		"/(app)/(private)/my/lists/[id]/edit": [21,[2,3]],
		"/(app)/(private)/my/protocols": [22,[2,3]],
		"/(app)/(private)/my/protocols/recommended": [23,[2,3]],
		"/(app)/(private)/my/schedule/[[date]]": [24,[2,3]],
		"/(app)/(private)/my/stack/nutrients": [25,[2,3,5]],
		"/(app)/(private)/my/stack/products": [26,[2,3,5]],
		"/(app)/(private)/my/stack/protocols": [27,[2,3,5]],
		"/(app)/(private)/my/suppscore": [28,[2,3]],
		"/(app)/(private)/my/suppscore/complete": [29,[2,3]],
		"/(app)/(private)/my/suppscore/wizard": [30,[2,3]],
		"/(app)/(private)/my/suppscore/wizard/[slide]": [31,[2,3]],
		"/(app)/nutrients": [51,[2]],
		"/(app)/nutrients/popular": [53,[2]],
		"/(app)/nutrients/[slug]": [52,[2]],
		"/partner/[slug]": [100],
		"/pow": [101],
		"/(app)/products": [54,[2]],
		"/(app)/products/category/[slug]": [63,[2]],
		"/(app)/(private)/products/edit": [32,[2,3]],
		"/(app)/products/popular": [64,[2]],
		"/(app)/products/search": [65,[2]],
		"/(app)/(private)/products/submit/[[id]]": [33,[2,3]],
		"/(app)/products/[slug]": [55,[2]],
		"/(app)/products/[slug]/report-image-incorrect": [56,[2]],
		"/(app)/products/[slug]/report-label-incorrect": [57,[2]],
		"/(app)/products/[slug]/report-nutrient-amount-incorrect": [58,[2]],
		"/(app)/products/[slug]/report-nutrient-incorrect": [59,[2]],
		"/(app)/products/[slug]/report-other-problem": [60,[2]],
		"/(app)/products/[slug]/report-problem": [61,[2]],
		"/(app)/products/[slug]/report-purchase-link-missing": [62,[2]],
		"/(app)/(private)/profile": [34,[2,3]],
		"/(app)/(private)/profile/logs": [~35,[2,3]],
		"/(app)/protocols": [66,[2]],
		"/(app)/protocols/[slug]": [67,[2]],
		"/(app)/protocols/[slug]/match-confirm": [68,[2]],
		"/(app)/protocols/[slug]/select-products": [69,[2]],
		"/(app)/(private)/saves": [36,[2,3]],
		"/(app)/scanner/lookup": [70,[2]],
		"/sentry/example": [102],
		"/(app)/(private)/settings": [37,[2,3]],
		"/(app)/(private)/settings/allow-push-notifications": [38,[2,3]],
		"/(app)/(private)/settings/profile": [39,[2,3]],
		"/(app)/supplements": [71,[2]],
		"/(app)/supplements/popular": [73,[2]],
		"/(app)/supplements/[slug]": [72,[2]],
		"/(app)/trustscore": [74,[2]],
		"/(app)/u/popular": [81,[2]],
		"/(app)/u/[slug]": [75,[2,11]],
		"/(app)/u/[slug]/nutrients": [76,[2,11]],
		"/(app)/u/[slug]/products": [77,[2,11]],
		"/(app)/u/[slug]/profile": [78,[2,11]],
		"/(app)/u/[slug]/protocols": [79,[2,11]],
		"/(app)/u/[slug]/summary": [80,[2,11]],
		"/willcole": [103]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.js';